
import ClientTypeSelect from '@bd/admin/components/Forms/ClientTypeSelect.vue'
import { default as common } from '@bd/admin/config/common.json'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'
import {
  adminApi,
  ClientDto,
  ClientRole,
  PropertyMarket,
  UserRole,
} from '@bd/api'
import {
  AgentAutocomplete,
  CheckboxInput,
  NumberInput,
  Select,
  TextInput,
  ClientNoteTextArea,
} from '@bd/components'
import { useLatest } from '@bd/helpers'
import { computed, defineComponent, PropType, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ClientSaveForm',
  components: {
    TextInput,
    NumberInput,
    Select,
    ClientNoteTextArea,
    CheckboxInput,
    ClientTypeSelect,
    AgentAutocomplete,
  },
  props: {
    clientType: {
      type: String as PropType<ClientRole>,
      required: true,
    },
    sellerToBuyerDisabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['clientTypeChange'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const isSeller = computed(() => {
      return props.clientType === UserRole.SELLER
    })

    const propertyMarkets = enumConvertToValue(PropertyMarket, 'propertyMarket')

    const agentsSuggestions = shallowRef<ClientDto[]>([])
    const searchAgentSuggestions = useLatest(
      async (query: string | null) => {
        return query != null
          ? (
              await adminApi.employeeByQuery({
                query,
                userRole: UserRole.AGENT,
                size: common.agentsSearchPageSize,
              })
            ).data.content
          : []
      },
      (resp) => (agentsSuggestions.value = resp),
    )
    const onClientTypeChange = (clientType: ClientRole) => {
      emit('clientTypeChange', clientType)
    }

    return {
      t,
      isSeller,
      propertyMarkets,
      onClientTypeChange,
      notesRows: common.notesRows,
      searchAgentSuggestions,
      agentsSuggestions,
    }
  },
})
