<template>
  <div class="client-type-select-button">
    <Svg :src="clientTypeIcon" class="mr-2" />
    <span>{{ option.name }}</span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { Svg } from '@bd/components'
import { SelectData } from '@bd/admin/types'

export default defineComponent({
  name: 'ClientTypeSelectButton',
  components: {
    Svg,
  },
  props: {
    option: {
      type: Object as PropType<SelectData>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const clientTypeIcon = computed(() => {
      return require(`@bd/admin/assets/icons/${
        props.isSelected ? 'check.svg' : 'no-check.svg'
      }`)
    })

    return {
      clientTypeIcon,
    }
  },
})
</script>

<style lang="scss" scoped>
.client-type-select-button {
  width: 100%;
  @include flex;
}
</style>
