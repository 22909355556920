
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import ClientSaveForm from '@bd/admin/components/Clients/ClientSaveForm.vue'
import { ClientEventMessages } from '@bd/admin/config/toast'
import { useAppStore } from '@bd/admin/store'
import {
  BuyerSaveDto,
  ClientDetailsDto,
  ClientRole,
  SellerSaveDto,
  UserRole,
} from '@bd/api'
import { adminValidationService } from '@bd/components'
import { addBuyerSchema, addSellerSchema } from '@bd/components/yup'
import { toRawDeep } from '@bd/helpers'
import { useToast } from 'primevue/usetoast'
import { useForm } from 'vee-validate'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { AnyObjectSchema } from 'yup'

const fillDefaults = (client: Partial<ClientDetailsDto>) => ({
  ...toRawDeep(client),
  sellerDeveloper: client.sellerDeveloper ?? false,
  notes: undefined,
})

type ClientSaveFormModel = Omit<ClientDetailsDto, 'notes'> & { note?: string }

export default defineComponent({
  name: 'ClientSave',
  components: { AppContentLayout, ClientSaveForm },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()
    const route = useRoute()
    const toast = useToast()

    const isAddClientMode = computed(() => route.name === 'ClientSave')
    const clientDetails = store.state.clients?.clientDetails
    const hasAnyOffer = computed<boolean>(() => {
      if (!clientDetails) {
        return false
      }
      return (
        clientDetails?.roles.includes(UserRole.SELLER) &&
        !!clientDetails.offerId
      )
    })

    const initialValues = computed<Partial<ClientSaveFormModel>>(() => {
      return isAddClientMode.value
        ? fillDefaults({})
        : fillDefaults(store.state.clients?.clientDetails || {})
    })

    const clientType = computed(
      () => store.state.clients?.selectedClientType ?? UserRole.SELLER,
    )

    const validationSchema = computed<AnyObjectSchema>(() => {
      return clientType.value === UserRole.SELLER
        ? addSellerSchema
        : addBuyerSchema
    })

    const form = useForm<Partial<ClientSaveFormModel>>({
      validationSchema,
      initialValues,
    })

    const isBuyerSelected = computed(
      () => store.state.clients?.selectedClientType === UserRole.CUSTOMER,
    )

    const sellerToBuyerDisabled = computed<boolean>(
      () =>
        !isAddClientMode.value && hasAnyOffer.value && isBuyerSelected.value,
    )

    const onClientTypeChange = async (clientType: ClientRole) => {
      await store.dispatch('clients/setSelectedClientType', clientType)
      form.setValues(fillDefaults(store.state.clients?.clientDetails || {}))
    }

    const getBuyerSaveDto = (
      clientDetails: ClientSaveFormModel,
    ): BuyerSaveDto => {
      return {
        city: clientDetails.customerCity,
        description: clientDetails.description,
        firstName: clientDetails.firstName,
        lastName: clientDetails.lastName,
        phoneNumber: clientDetails.phoneNumber,
        district: clientDetails.customerDistrict,
        areaMin: clientDetails.customerAreaMin,
        areaMax: clientDetails.customerAreaMax,
        priceMin: clientDetails.customerPriceMin,
        priceMax: clientDetails.customerPriceMax,
        market: clientDetails.customerMarket,
        note: clientDetails.note,
        email: clientDetails.email || undefined,
        agentId: clientDetails.agent?.userId ?? null,
      }
    }

    const getSellerSaveDto = (
      clientDetails: ClientSaveFormModel,
    ): SellerSaveDto => {
      return {
        firstName: clientDetails.firstName,
        lastName: clientDetails.lastName,
        developer: clientDetails.sellerDeveloper,
        phoneNumber: clientDetails.phoneNumber,
        description: clientDetails.description,
        note: clientDetails.note,
        email: clientDetails.email || undefined,
        agentId: clientDetails.agent?.userId ?? null,
      }
    }

    const getClientSaveDto = (
      clientDetails: ClientDetailsDto,
    ): SellerSaveDto | BuyerSaveDto => {
      return clientType.value === UserRole.SELLER
        ? getSellerSaveDto(clientDetails)
        : getBuyerSaveDto(clientDetails)
    }

    const onSubmit = form.handleSubmit(async (formData, context) => {
      const capitalizedClientType =
        clientType.value.charAt(0).toUpperCase() +
        clientType.value.slice(1).toLowerCase()
      const clientAction = `${
        isAddClientMode.value ? 'add' : 'update'
      }${capitalizedClientType}`
      try {
        const clientSaveData = getClientSaveDto(formData as ClientDetailsDto)
        await store.dispatch(`clients/${clientAction}`, {
          ...clientSaveData,
          userId: +route.params.id ?? undefined,
        })
        router.back()
        toast.add(
          isAddClientMode.value
            ? ClientEventMessages.saveSuccess
            : ClientEventMessages.updateSuccess,
        )
      } catch (error) {
        const errorsData = adminValidationService.handleValidation(error)
        errorsData && context.setErrors(errorsData)
        toast.add(
          isAddClientMode.value
            ? ClientEventMessages.saveError
            : ClientEventMessages.updateError,
        )
      }
    })

    const removeClient = async () => {
      try {
        await store.dispatch('clients/removeClient', route.params.id)
        router.push({ name: 'Clients' })
        toast.add(ClientEventMessages.removeSuccess)
      } catch (e) {
        toast.add(ClientEventMessages.removeError)
      }
    }

    if (!isAddClientMode.value) {
      store.dispatch('clients/client', route.params.id)
    }

    return {
      t,
      form,
      onSubmit,
      isAddClientMode,
      clientType,
      onClientTypeChange,
      removeClient,
      sellerToBuyerDisabled,
    }
  },
})
