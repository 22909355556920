<template>
  <div class="client-type-select field-spacing white-bg">
    <div class="mb-2">
      <span class="body-12">{{ label }}</span>
    </div>
    <SelectButton v-model="selected" :options="items" optionValue="value">
      <template #option="{ option }">
        <ClientTypeSelectButton
          :option="option"
          :isSelected="option.value === selected"
        />
      </template>
    </SelectButton>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import SelectButton from 'primevue/selectbutton'
import { UserType } from '@bd/components'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'
import ClientTypeSelectButton from '@bd/admin/components/Forms/ClientTypeSelectButton.vue'
import { ClientRole } from '@bd/api'

export default defineComponent({
  name: 'ClientTypeSelect',
  components: { SelectButton, ClientTypeSelectButton },
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String as PropType<ClientRole>,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selected = computed({
      get: () => props.modelValue,
      set: (value: string) => {
        emit('update:modelValue', value)
      },
    })
    const items = enumConvertToValue(
      { SELLER: UserType.Seller, CUSTOMER: UserType.Buyer },
      'userRole',
    )

    return {
      selected,
      items,
    }
  },
})
</script>

<style lang="scss" scoped></style>
