
import { computed, defineComponent, PropType } from 'vue'
import { Svg } from '@bd/components'
import { SelectData } from '@bd/admin/types'

export default defineComponent({
  name: 'ClientTypeSelectButton',
  components: {
    Svg,
  },
  props: {
    option: {
      type: Object as PropType<SelectData>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const clientTypeIcon = computed(() => {
      return require(`@bd/admin/assets/icons/${
        props.isSelected ? 'check.svg' : 'no-check.svg'
      }`)
    })

    return {
      clientTypeIcon,
    }
  },
})
