<template>
  <div class="client-save-form">
    <div class="row">
      <div class="col-md-6">
        <div class="row mb-4">
          <div class="col-md-12">
            <span class="h-4 mb-3">{{ t('clients.information') }}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <ClientTypeSelect
              :modelValue="clientType"
              :label="t('clients.clientType')"
              @update:modelValue="onClientTypeChange"
            />
            <div class="warning" v-if="sellerToBuyerDisabled">
              {{ t('clients.seller_role_change_hint') }}
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <TextInput
              name="firstName"
              type="text"
              :label="t('first_name')"
              :placeholder="t('clients.firstNamePlaceholder')"
              :floating="false"
              whiteBackground
            />
          </div>
          <div class="col-md-6">
            <TextInput
              name="lastName"
              type="text"
              :label="t('last_name')"
              :placeholder="t('clients.lastNamePlaceholder')"
              :floating="false"
              whiteBackground
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <TextInput
              name="email"
              type="text"
              :label="t('clients.email')"
              :placeholder="t('clients.emailPlaceholder')"
              :floating="false"
              whiteBackground
            />
          </div>
          <div class="col-md-6">
            <TextInput
              name="phoneNumber"
              type="text"
              :label="t('clients.phone')"
              :placeholder="t('clients.phonePlaceholder')"
              :floating="false"
              whiteBackground
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <TextInput
              name="description"
              type="text"
              :label="t('clients.description')"
              :placeholder="t('clients.descriptionPlaceholder')"
              :floating="false"
              whiteBackground
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <CheckboxInput
              v-if="isSeller"
              name="sellerDeveloper"
              :label="t('clients.markDeveloper')"
            />
          </div>
        </div>
        <template v-if="!isSeller">
          <div class="row mb-3">
            <div class="col-md-12">
              <TextInput
                name="customerCity"
                type="text"
                :label="t('clients.city')"
                :placeholder="t('clients.cityPlaceholder')"
                :floating="false"
                whiteBackground
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <NumberInput
                name="customerAreaMin"
                :label="t('clients.areaMin')"
                :placeholder="t('clients.areaMinPlaceholder')"
                :floating="false"
                whiteBackground
                unit="area"
              />
            </div>
            <div class="col-md-6">
              <NumberInput
                name="customerAreaMax"
                :label="t('clients.areaMax')"
                :placeholder="t('clients.areaMaxPlaceholder')"
                :floating="false"
                whiteBackground
                unit="area"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <NumberInput
                name="customerPriceMin"
                :label="t('clients.priceMin')"
                :placeholder="t('clients.priceMinPlaceholder')"
                :floating="false"
                unit="currency"
                whiteBackground
              />
            </div>
            <div class="col-md-6">
              <NumberInput
                name="customerPriceMax"
                :label="t('clients.priceMax')"
                :placeholder="t('clients.priceMaxPlaceholder')"
                :floating="false"
                unit="currency"
                whiteBackground
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <Select
                name="customerMarket"
                :options="propertyMarkets"
                :label="t('clients.form.market')"
                :placeholder="t('clients.marketPlaceholder')"
                whiteBackground
              />
            </div>
          </div>
        </template>
      </div>
      <div class="col-md-6">
        <div class="row mb-4">
          <div class="col-md-12 mb-3"></div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <AgentAutocomplete
              :items="agentsSuggestions"
              :label="t('clients.agent')"
              :placeholder="t('clients.agentPlaceholder')"
              whiteBackground
              @search="searchAgentSuggestions"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <ClientNoteTextArea
              name="note"
              :label="t('clients.note')"
              :placeholder="t('clients.note_placeholder')"
              :rows="notesRows"
              whiteBackground
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ClientTypeSelect from '@bd/admin/components/Forms/ClientTypeSelect.vue'
import { default as common } from '@bd/admin/config/common.json'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'
import {
  adminApi,
  ClientDto,
  ClientRole,
  PropertyMarket,
  UserRole,
} from '@bd/api'
import {
  AgentAutocomplete,
  CheckboxInput,
  NumberInput,
  Select,
  TextInput,
  ClientNoteTextArea,
} from '@bd/components'
import { useLatest } from '@bd/helpers'
import { computed, defineComponent, PropType, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ClientSaveForm',
  components: {
    TextInput,
    NumberInput,
    Select,
    ClientNoteTextArea,
    CheckboxInput,
    ClientTypeSelect,
    AgentAutocomplete,
  },
  props: {
    clientType: {
      type: String as PropType<ClientRole>,
      required: true,
    },
    sellerToBuyerDisabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['clientTypeChange'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const isSeller = computed(() => {
      return props.clientType === UserRole.SELLER
    })

    const propertyMarkets = enumConvertToValue(PropertyMarket, 'propertyMarket')

    const agentsSuggestions = shallowRef<ClientDto[]>([])
    const searchAgentSuggestions = useLatest(
      async (query: string | null) => {
        return query != null
          ? (
              await adminApi.employeeByQuery({
                query,
                userRole: UserRole.AGENT,
                size: common.agentsSearchPageSize,
              })
            ).data.content
          : []
      },
      (resp) => (agentsSuggestions.value = resp),
    )
    const onClientTypeChange = (clientType: ClientRole) => {
      emit('clientTypeChange', clientType)
    }

    return {
      t,
      isSeller,
      propertyMarkets,
      onClientTypeChange,
      notesRows: common.notesRows,
      searchAgentSuggestions,
      agentsSuggestions,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.p-checkbox-box) {
  border-radius: 50%;
}
.warning {
  color: $zest;
}
</style>
